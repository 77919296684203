@import '../../../scss/vars';
.headerTop,
.headerWithoutSearch {
  padding: 60px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $text;
}

.logo,
.logoWithoutSearch {
  font-size: 32px;
}

.innerControl {
  display: flex;
  gap: 16px;
}

.UserPanel {
  position: relative;
  &:hover .control {
    z-index: 1;
    opacity: 100%;
    transform: translateY(0);
    transition: all 0.9s;
  }
}

.userName {
  cursor: pointer;
  height: 46px;
  width: 46px;
  background: $bright_border;
  border-radius: 50%;
  color: $light_gray;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

.userIcon,
.userIconWithoutSearch {
  display: none;
}

.naw {
  display: flex;
  justify-content: center;
  gap: 45px;
  margin-bottom: 40px;
}

.nawItem {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: $light_border;
  cursor: pointer;
  transition: all 0.9s;
  &:hover {
    transition: all 0.9s;
    color: $title;
  }
}

.active {
  color: $title;
}

.burgerBtn {
  display: none;
  cursor: pointer;
  width: 46px;
  height: 46px;
  background-image: url('../../../assets/img/burger.svg');
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  position: relative;
}

.hide {
  display: none;
}

.indent {
  display: none;
  width: 53px;
}

.mobileSidebarContainer {
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(34, 34, 34, 0.8);
  position: fixed;
  transition: all 0.5s ease-in-out;
  z-index: 99999;
}

.bgActive {
  opacity: 1;
  visibility: visible;
  overflow: auto;
}

@media (max-width: 1024px) {
  .headerTop,
  .headerWithoutSearch {
    padding: 58px 45px;
  }
}

@media (max-width: 768px) {
  .headerTop,
  .headerWithoutSearch {
    display: flex;
    justify-content: flex-start;
    padding: 65px 0 56px 0;
  }
  .innerControl {
    display: none;
  }
  .logo,
  .logoWithoutSearch {
    flex-basis: 100%;
    text-align: center;
  }
  .burgerBtn {
    display: flex;
    flex-shrink: 0;
  }
  .naw {
    display: none;
  }
  .mobileSidebarContainer {
    opacity: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(34, 34, 34, 0.8);
    position: fixed;
    transition: all 0.5s ease-in-out;
    z-index: 99999;
  }

  .bgActive {
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .indent {
    display: block;
  }
}

@media (max-width: 620px) {
  .headerTop,
  .headerWithoutSearch {
    padding: 40px 0 35px 0;
  }
}

@media (max-width: 480px) {
  .headerTop {
    background-color: $title;
    border-radius: 0px 0px 30px 30px;
    padding: 20px 16px 100px;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
  }
  .headerWithoutSearch {
    padding: 20px 0;
    width: 100%;
  }
  .controlPanel {
    position: static;
    gap: 16px;
  }
  .userIcon,
  .userIconWithoutSearch {
    display: block;
    width: 24px;
    height: 24px;
  }
  .burgerBtn {
    width: 24px;
    height: 24px;
    background-image: url('../../../assets/img/burger.svg');
  }
  .userName {
    display: none;
  }
  .logo {
    font-size: 18px;
    color: $white;
  }

  .logoWithoutSearch {
    font-size: 18px;
    color: $black;
  }

  .indent {
    width: 28px;
  }
}

@media (max-width: 380px) {
  .burgerBtn {
    width: 24px;
    height: 24px;
  }
  .logo,
  .logoWithoutSearch {
    font-size: 24px;
    line-height: 29px;
  }
}
