@import '../../../scss/vars';

.pageWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 332px);

    @media (max-width: 1024px) {
        height: calc(100vh - 392px);
    }

    @media (max-width: 768px) {
        flex-direction: column;
        height: calc(100vh - 650px);
    }

    @media (max-width: 480px) {
        height: calc(100vh - 292px);
    }
}

.buttonsWrap {
    display: flex;
    gap: 141px;

    @media (max-width: 1170px) {
        gap: 65px;
    }

    @media (max-width: 1024px) {
        gap: 50px;
    }

    @media (max-width: 768px) {
        gap: 34px;
    }

    @media (max-width: 480px) {
        flex-direction: column-reverse;
        gap: 40px;
    }
}

.specialistsButtons {
    width: 390px;
    height: 270px;
    padding: 63px 20px;
    border-radius: 26px;
    border: 0.6px solid $light_border;
    box-shadow: 0px 10px 32px -4px rgba(24, 39, 75, 0.1), 0px 6px 14px -6px rgba(24, 39, 75, 0.12);
    display: flex;
    flex-direction: column;
    gap: 35px;
    align-items: center;
    color: $btn;
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
    transition: all 0.5s;

    .imageWrap>svg {
        fill: $btn;

        @media (max-width: 480px) {
            width: 32px;
            height: 32px;
        }
    }

    &:hover {
        border: 0.6px solid $btn;
        transition: all 0.5s;
    }

    &:active {
        color: $hover;
        border: none;

        .imageWrap>svg {
            fill: $hover;
        }
    }

    @media (max-width: 1170px) {
        width: 329px;
        height: 230px;
        padding: 43px 0px;
    }

    @media (max-width: 480px) {
        width: 288px;
        height: 136px;
        padding: 0;
        gap: 14px;
        justify-content: center;
        align-items: center;
    }
}

.title {
    display: none;
    color: $text;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    margin-bottom: 64px;

    @media (max-width: 768px) {
        display: block;
    }

    @media (max-width: 480px) {
        display: none;
    }
}

.subtitle {

    @media (max-width: 480px) {
        font-size: 16px;
    }
}