@import '../../scss/vars';

.backHomeLink {
  position: absolute;
  top: 44px;
  right: 57px;
  font-weight: 600;
  font-size: 16px;
  line-height: 137%;
  color: $btn;

  .closeIcon {
    height: 14px;
    width: 14px;

    & path {
      fill: $light_gray_blue;
    }
  }

  .arrowTab {
    transform: rotateY(180deg);
    margin-right: 10px;
  }
}

.logoLink {
  position: absolute;
  top: 60px;
  left: 100px;
  width: 177px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    width: 100%;
    height: 100%;
  }
}

.label {
  margin-bottom: 50px;
}

.text {
  color: #9ea1aa;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 32px;
}

.innerForm {
  display: flex;
  flex-direction: column;
  gap: 44px;
}

.btn {
  width: 264px;
  margin: 0 auto;
  padding: 16px;
  font-size: 20px;
}

@media (max-width: 1170px) {
  .logoLink {
    left: 60px;
  }
}

@media (max-width: 1024px) {
  .backHomeLink {
    top: 20px;
    right: 20px;
  }

  .logoLink {
    width: 135px;
    height: 46px;
    top: 73px;
    left: 76px;
  }
}

@media (max-width: 768px) {
  .logoLink {
    top: 39px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 480px) {
  .logoLink {
    top: 20px;
    width: 70px;
    height: 24px;
  }

  .text {
    font-size: 12px;
  }

  .btn {
    width: 100%;
    padding: 10px 24px;
    height: 40px;
    font-size: 14px;
    margin-top: 20px;
  }

  .innerForm {
    gap: 20px;
  }
}

@media (max-width: 320px) {
  .text {
    padding: 0;
  }
}
