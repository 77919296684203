@import '../../scss/vars';
.innerInput {
  display: flex;
  gap: 14px;
  margin-bottom: 25px;
  align-items: flex-end;
}

.innerInputTwo {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 32px;
}

.private {
  color: $gray_text;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 44px;
  a {
    color: $btn;
  }
}

.btn {
  display: inherit;
  max-width: 264px;
  margin: 0 auto;
  padding: 16px 24px;
  height: 60px;
}

@media (max-width: 1024px) {
  .private {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .innerInput {
    margin-bottom: 20px;
  }
  .innerInputTwo {
    gap: 20px;
  }
}

@media (max-width: 480px) {
  .innerInput {
    flex-direction: column;
    margin-bottom: 14px;
  }
  .innerInputTwo {
    gap: 14px;
    margin-bottom: 24px;
  }
  .private {
    font-size: 9px;
    margin-bottom: 24px;
  }
  .btn {
    padding: 10px 24px;
    height: 40px;
    font-size: 14px;
    max-width: 100%;
  }
}
