@import '../../../scss/vars';

.userControl {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  opacity: 0;
  transform: translateY(-30px);
  transition: all 0.9s;
  z-index: -1;
  right: 0;
  top: 60px;
  background-color: $white;
  border-radius: 15px;
  padding: 20px;
  width: 356px;
  height: 271px;
  color: $border;
  font-weight: 500;
  box-shadow: 0px 6px 12px rgba(24, 39, 75, 0.12), 0px 8px 24px rgba(24, 39, 75, 0.08);
}

.userNameOne {
  width: 46px;
  height: 46px;
  background-color: #0176d3;
  border-radius: 50%;
  color: $light_gray;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

.userName {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: $black;
  margin-bottom: 6px;
}

.userEmail {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #bfc1c8;
}

.logout {
  padding-top: 18px;
  border-top: 1px solid #bfc1c8;
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
}

.logoutQuestion {
  border-top: 1px solid #bfc1c8;
}

.logoutText {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #032d60;
}

.logoutQuestionText {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-top: 12px;
  margin-bottom: 35px;
}

.logoutQuestionBtn {
  display: flex;
  gap: 36px;
}

.logoutIcon {
  svg {
    height: 24px;
    width: 24px;
    color: #032d60;
  }
}

.btn {
  font-size: 16px;
  line-height: 19px;
}
