@import "../../../scss/vars";

.plussquareicon {
    width: 85px;
    height: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    background: $white;
    border-radius: 25px;
    filter: drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.04)) drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.12));

    & svg {
        width: 44px;
        height: 44px;

        @media(max-width:525px) {
            width: 26px;
            height: 26px;
        }
    }

    & svg path {
        stroke: $black;
        transition: all 0.5s;
    }

    &:hover {
        cursor: pointer;
    }

    &:hover svg path {
        stroke: $bright_border;
    }

    @media(max-width:525px) {
        border-radius: 18px;
        width: 60px;
        height: 60px;
    }
}