@import "../../scss/vars";

.contactsPageWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    width: 100%;
    font-size: 32px;
    font-weight: 500;
    line-height: 45px;
    margin-bottom: 20px;
}

.text {
    width: 100%;
    font-size: 22px;
    font-weight: 500;
    line-height: 31px;
    margin-bottom: 50px;
}

.bigText {
    margin-top: 126px;
    display: flex;
    align-items: center;
    flex-direction: column;

    p {
        font-size: 32px;
        font-weight: 600;
        line-height: 45px;
    }

    a {
        margin-top: 30px;
        font-size: 40px;
        font-weight: 600;
        line-height: 56px;
    }
}

.sharingButtons {
    display: flex;
    gap: 25px;
    margin-top: 30px;
}

@media (max-width: 1024px) {
    .title {
        font-size: 30px;
        line-height: 42px;
    }

    .text {
        font-size: 20px;
        line-height: 28px;
    }

    .bigText {
        p {
            font-size: 30px;
            line-height: 42px;
        }
    }
}

@media (max-width: 768px) {
    .title {
        font-size: 28px;
        line-height: 40px;
        margin-bottom: 39px;
    }

    .text {
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 66px;
    }

    .bigText {
        margin-top: 113px;

        p {
            font-size: 28px;
            line-height: 40px;
        }

        a {
            font-size: 38px;
            margin-top: 37px;
            line-height: 53px;
        }
    }

    .sharingButtons {
        margin-top: 38px;
    }
}

@media (max-width: 640px) {
    .sharingButtons {
        flex-direction: column;
    }
}

@media (max-width: 480px) {
    .title {
        font-size: 16px;
        line-height: 23px;
        margin-bottom: 16px;
    }

    .text {
        line-height: 20px;
        font-size: 14px;
        margin-bottom: 13px;
    }

    .bigText {
        margin-top: 47px;

        p {
            font-size: 14px;
            line-height: 20px;
        }

        a {
            font-size: 24px;
            line-height: 34px;
            margin-top: 25px;
        }
    }

    .sharingButtons {
        flex-direction: column;
        margin-top: 24px;
        gap: 16px;
    }
}
