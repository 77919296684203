.wrap {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 240px;
  background-color: #032d60;
  border-radius: 30px;
  padding: 0 173px;
}

.title {
  font-weight: 400;
  font-size: 40px;
  line-height: 120%;
  color: #ffffff;
  span {
    font-weight: 600;
  }
}

@media (max-width: 1170px) {
  .wrap {
    padding: 0 80px;
  }
}

@media (max-width: 1024px) {
  .wrap {
    padding: 40px 40px;
  }
}

@media (max-width: 768px) {
  .wrap {
    height: fit-content;
  }
  .title {
    font-size: 28px;
    line-height: 120%;
    color: #fff;
    margin-bottom: 15px;
  }
}

@media (max-width: 640px) {
  .title {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .wrap {
    padding: 0;
  }
  .title {
    display: none;
  }
}
