@import '../../../scss/vars';

.button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  transition: all 0.5s;
  &:hover {
    cursor: pointer;
    transition: all 0.5s;
  }
}

.primary {
  gap: 15px;
  border: 1px solid $text_basic;
  color: $text_basic;
  padding: 15px 10px;
  transition: all 0.5s;
  &:hover {
    color: $button_1;
    transition: all 0.5s;
  }
  &:active {
    background-color: $button_1;
    color: $backgr_white;
  }
  &:disabled {
    background-color: $button_disabled;
    color: $backgr_white;
  }
}

.secondary {
  padding: 15px 10px;
  background-color: $text_basic;
  color: $backgr_white;
  font-size: 22px;
  line-height: 140%;
  border: none;
  transition: all 0.5s;
  &:hover {
    background-color: $btn_hover;
    transition: all 0.5s;
  }
  &:active {
    background-color: $button_1;
  }
  &:disabled {
    background-color: $button_disabled;
  }
}

.icons {
  border-radius: 4px;
  background-color: #0b84ee;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
  &:hover {
    color: #459eeb;
    transition: all 0.5s;
  }
  &:active {
    background-color: $text_basic;
  }
  &:disabled {
    background-color: $button_disabled;
  }
}

.disabled {
  pointer-events: none;
  cursor: no-drop;
}

@media (max-width: 320px) {
  .secondary {
    padding: 12px 10px;
  }
}
