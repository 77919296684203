@import '../../../scss/_vars';

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 18px;
  background: $title;
  padding: 60px 20px 40px;
  color: $white;

  .logo {
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 38px;
  }

  .innerNav {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 56px;
    width: 237px;

    @media (max-width: 320px) {
      margin-bottom: 0;
    }
  }

  .itemSidebar,
  .activeItemSidebar {
    height: 40px;
    font-weight: 500;
    line-height: 36px;
    font-size: 16px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.5s;
    display: flex;
    align-items: center;
    justify-content: left;
    a {
      display: flex;
      align-items: center;
      justify-content: left;
      width: 100%;
    }
    svg {
      margin-right: 10px;
      margin-left: 10px;
      width: 28px;
      height: 28px;
    }
    &:hover {
      transition: all 0.5s;
      background-color: $backgr_blue;
    }
  }

  .itemSidebarExit {
    margin-top: 56px; 

    @media (max-width: 320px) {
      margin-top: 10px;
    }
  }

  .activeItemSidebar {
    color: $title;
  }

  .activeItemSidebar {
    background-color: $white;
    & svg {
      filter: brightness(0) saturate(100%) invert(12%) sepia(95%) saturate(1380%) hue-rotate(197deg) brightness(97%)
        contrast(104%);
    }
    pointer-events: none;
  }

  .activeIconSidebar {
    border-color: $title;
  }
}

.close {
  display: none;
}

@media all and (max-width: 1124px) {
  .sidebar {
    padding: 60px 20px 40px;
  }
  .itemSidebar,
  .activeItemSidebar {
    font-size: 14px !important;
  }
  svg {
    flex-shrink: 0;
    margin-right: 5px;
    margin-left: 5px;
  }
  .sidebar,
  .sidebarActive {
    padding: 60px 20px 40px;
  }
}

@media all and (max-width: 768px) {
  .sidebar {
    transform: translateX(-150%);
    transition: all 0.7s;
    width: 277px;
    position: absolute;
    top: 10px;
    z-index: 1;
  }
  .sidebarActive {
    transform: translateX(5px);
    transition: all 0.7s;
    z-index: 1;
  }
}

@media (max-width: 320px) {
  .sidebar {
    transform: translateY(-150%);
    width: 100%;
    padding: 20px 12px;
    justify-content: flex-start;
    top: 0;
    left: 0;
  }
  .sidebarActive {
    transform: translateY(-1%);
    border-radius: 0;
    height: fit-content;
  }
  .innerNav {
    width: 100% !important;
    margin-bottom: 0;
  }
  .close {
    display: block;
    position: absolute;
    top: 20px;
    right: 16px;
    path {
      stroke: white;
    }
  }
  .itemSidebar,
  .activeItemSidebar {
    font-size: 14px;
  }

  .searchHistoryContainer {
    width: 100%;
    height: 40px !important;
    flex-direction: row !important;
    align-items: center;
    border-radius: 10px !important;
    gap: 12px;
    svg {
      margin: 0 !important;
    }

    .searchHistoryTitle {
      margin-bottom: 0 !important;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .searchHistoryDescription {
    display: none;
  }
}
