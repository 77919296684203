@import '../../../scss/vars';

.pageWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 332px);
  @media (max-width: 640px) {
    height: auto;
  }
}

.buttonsWrap {
  display: flex;
  gap: 141px;
  @media (max-width: 1300px) {
    display: flex;
    gap: 14px;
  }
  @media (max-width: 640px) {
    flex-direction: column;
    width: 100%;
    gap: 40px;
  }
}

.specialistsButtons {
  width: 390px;
  height: 270px;
  padding: 63px 20px;
  border-radius: 26px;
  border: 0.6px solid $light_border;
  box-shadow: 0px 10px 32px -4px rgba(24, 39, 75, 0.1), 0px 6px 14px -6px rgba(24, 39, 75, 0.12);
  display: flex;
  flex-direction: column;
  gap: 35px;
  align-items: center;
  color: $btn;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  transition: all 0.5s;

  .imageWrap > svg {
    fill: $btn;
  }

  &:hover {
    border: 0.6px solid $btn;
    transition: all 0.5s;
  }

  &:active {
    color: $hover;
    border: none;

    .imageWrap > svg {
      fill: $hover;
    }
  }
  @media (max-width: 1400px) {
    width: 378px;
  }
  @media (max-width: 1200px) {
    width: 314px;
  }
  @media (max-width: 768px) {
    width: 288px;
    height: 240px;
  }
  @media (max-width: 640px) {
    width: 100%;
    max-width: 100%;
  }
  @media (max-width: 480px) {
    padding: 36px 20px;
    height: auto;
  }
}
