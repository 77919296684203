@import "../../../../scss/vars";

.template {
  &__solutions {
    margin-bottom: 40px;
  }
}

.solutions {
  &__title {
    font-size: 24px;
    font-weight: 500;
    color: $text;
    margin-bottom: 32px;

    @media (max-width:525px) {
      font-size: 16px;
    }
  }

  &__card {
    width: 290px;
    height: 372px;
    border-radius: 20px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23BFC1C8FF' stroke-width='2' stroke-dasharray='10%2c 9' stroke-dashoffset='25' stroke-linecap='square'/%3e%3c/svg%3e");
    background-position: bottom;
    padding: 20px 15px 80px;
    cursor: pointer;

    &:hover {
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%230176D3FF' stroke-width='2' stroke-dasharray='10%2c 9' stroke-dashoffset='25' stroke-linecap='square'/%3e%3c/svg%3e");
      transition: all 0.5s;
    }

    &:active {
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%230176D3FF' stroke-width='3' stroke-dasharray='10%2c 9' stroke-dashoffset='25' stroke-linecap='square'/%3e%3c/svg%3e");
      transition: all 0.5s;
    }

    @media(max-width:1439.5px) {
      width: 252px;
    }

    @media(max-width:1169.5px) {
      width: 234px;
    }

    @media(max-width:1023.5px) {
      width: 240px;
    }

    @media(max-width:767.5px) {
      width: 100%;
    }

    @media(max-width:525px) {
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23BFC1C8FF' stroke-width='2' stroke-dasharray='10%2c 9' stroke-dashoffset='25' stroke-linecap='square'/%3e%3c/svg%3e");
    }
  }
}

.card {
  &__addBtn {
    width: 100%;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $backgr_light;
    margin-bottom: 80px;

    & svg {
      width: 54px;
      height: 54px;
      fill: $bright_border;

      & path:not(:first-child) {
        stroke: $white;
      }
    }
  }

  &__desc {
    color: $text;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
  }
}

.templatesModal__background {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: 1;
  background: $backgr_shadow;
  z-index: 6;
}

.templatesModal__backgroundClosed {
  display: none;
}

.templatesModal {
  padding: 30px 105px;
  border-radius: 20px;
  background: $white;
  width: 1134px;
  max-width: calc(100vw - 120px);
  max-height: calc(100vh - 40px);
  overflow: hidden;
  overflow-y: scroll;

  @media(max-width:1440px) {
    padding: 30px 57px 30px 70px;
  }

  @media(max-width:1170px) {
    max-width: calc(100vw - 40px);
    padding: 28px 53px 25px 66px;
  }

  @media(max-width:1024px) {
    max-width: calc(100vw - 32px);
    padding: 28px 21px 64px 35px;
  }

  @media(max-width:768px) {
    padding: 24px 16px 32px;
  }

  &__title {
    font-size: 30px;
    line-height: 36px;
    color: $admin_platform_title;
    font-weight: 500;

    @media(max-width:1170px) {
      font-size: 28px;
    }

    @media(max-width:768px) {
      font-size: 18px;
    }

    @media(max-width:480px) {
      font-size: 14px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 40px 0px;

    @media(max-width:1170px) {
      margin: 35px 0px;
    }

    @media(max-width:1024px) {
      margin: 26px 0px 36px;
    }

    @media(max-width:768px) {
      margin: 0px 0px 20px;
      flex-direction: column;
      gap: 10px;
    }
  }

  &__chooseBtnWrap {
    display: flex;
    align-items: center;
    gap: 10px;

    & svg {
      width: 24px;
      height: 24px;
      fill: $btn;
    }

    & p {
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
      color: $btn;
    }

    &:hover {
      & svg {
        fill: $hover;
      }

      & p {
        color: $hover;
      }
    }

    /*     @media(max-width:768px) {
      display: none;
    } */
  }

  &__chooseBtnWrapActive {
    & svg {
      fill: $title;
    }

    & p {
      color: $title;
      font-weight: 600;
    }
  }
}

.platformWrap {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(291px, 1fr));
  grid-gap: 25px;
  width: 100%;
  min-height: calc(100vh - 465px);
  /*   max-height: calc(100vh - 375px);
  overflow: hidden;
  overflow-y: scroll;
 */
  margin-bottom: 58px;

  @media(max-width:1024px) {
    margin-bottom: 32px;
  }

  @media(max-width:768px) {
    margin-bottom: 40px;
  }
}

.platformWrapList {
  display: grid;
  grid-template-columns: repeat(auto-fill, 290px);
  grid-gap: 25px;
  width: 100%;

  @media(max-width:1439.5px) {
    grid-template-columns: repeat(auto-fill, 252px);
    grid-gap: 15px;
  }

  @media(max-width:1169.5px) {
    grid-template-columns: repeat(auto-fill, 234px);
  }

  @media(max-width:1023.5px) {
    grid-template-columns: repeat(auto-fill, 240px);
    grid-gap: 9px;
  }

  @media(max-width:565px) {
    grid-template-columns: repeat(auto-fill, 100%);
  }
}

.templatedCardSelectMode {
  transition: all 0.1s;

  &:hover {
    border: 0.6px solid $bright_border;
  }
}

.platformWrapPopular {
  margin-bottom: 32px;
}

.modalBtnsWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 80px;
}

.modalBtns {
  &__wrap {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 22px;

    @media(max-width:768px) {
      flex-wrap: wrap;
      gap: 24px;
    }
  }

  &__btn {
    width: 181px;
    font-size: 16px;
    font-weight: 400;

    @media(max-width:1170px) {
      font-size: 14px;
    }

    @media(max-width:480px) {
      width: 100%;
    }
  }
}

.templatedCardList {
  width: 290px;
  height: 372px;

  &:hover {
    transition: all 0.5s;
    border: 0.6px solid $bright_border;
  }

  &:active {
    border: 1px solid $bright_border;
  }

  @media(max-width:1439.5px) {
    width: 252px;
  }

  @media(max-width:1169.5px) {
    width: 234px;
  }

  @media(max-width:1023.5px) {
    width: 240px;
  }

  @media(max-width:767.5px) {
    width: 100%;
  }
}

@media (max-width: 1170px) {
  .templatedCard {
    max-width: 252px;
  }

  .platformWrap {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 16px;
  }
}

@media (max-width: 768px) {
  .templatedCard {
    max-width: 100%;
  }
}