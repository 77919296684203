@import '../../../../scss/vars';

.wrap {
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  border: 0.5px solid $light_border;
  padding: 44px 80px 40px;
  box-shadow: 0px 8px 24px -4px rgba(24, 39, 75, 0.08), 0px 6px 12px -6px rgba(24, 39, 75, 0.12);
  margin-top: 40px;
}

.containerWrap {
  display: inline-flex;
  margin-bottom: 25px;
}

.container {
  color: $title;
  font-size: 20px;
  font-weight: normal;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}

.title {
  color: $title;
  font-size: 20px;
  margin-bottom: 32px;
}

.icon {
  display: flex;
}

.selectedPlatforms {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 18px;
}

.selectedPlatformCard {
  max-width: 288px;
}

@media (max-width: 1390px) {
  .wrap {
    padding: 40px 40px 29px;
  }
}

@media (max-width: 1024px) {
  .wrap {
    padding: 40px 30px;
  }
}

@media (max-width: 768px) {
  .wrap {
    border: none;
    box-shadow: none;
    padding: 0;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 14px;
  }

  .subtitle {
    font-size: 16px;
  }

  .subtitle {
    gap: 8px;
  }
}
