@import '../../../scss/vars';

.switch {
  position: relative;
  width: 34px;
  height: 18px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
  background-color: $light_border;
  cursor: pointer;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  top: 1px;
  left: 1px;
  border-radius: 50%;
  background-color: $white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $btn;
}

input:checked + .slider:before {
  transform: translateX(16px);
}
