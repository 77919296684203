.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    transition: all 0.5s;
    box-sizing: border-box;
    color: transparent;
    padding: 10px 10px 10px 18px;
    gap: 10px;
    color: white;
    width: 186px;
    height: 50px;

    &:hover {
        cursor: pointer;
        transition: all 0.5s;
    }
}

.text {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    width: 124px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.iconWrap {
    display: flex;
    align-items: center;
}

.vkontakte {
    background-color: #0077ff;
    transition: all 0.5s;
    &:hover {
        background-color: #459eeb;
        transition: all 0.5s;
    }
}

.telegram {
    background-color: #2db0f2;
    transition: all 0.5s;
    &:hover {
        background-color: #64c6f7;
        transition: all 0.5s;
    }
}

.whatsApp {
    background: #48cf3c;
    transition: all 0.5s;
    &:hover {
        background-color: #5fe953;
        transition: all 0.5s;
    }
}

@media (max-width: 480px) {
    .btn {
        gap: 3px;
        padding: 8px 2px 8px 12px;
        width: 140px;
        height: 40px;
    }

    .iconWrap {
        width: 24px;
        height: 24px;
    }

    .text {
        width: 99px;
        height: 20px;
        font-size: 14px;
    }
}
