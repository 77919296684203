@import '../../scss/vars';

.detailsCard {
  color: $text;
  background-color: $white;
  border: 0.5px solid $light_border;
  box-shadow: 0px 8px 24px -4px rgba(24, 39, 75, 0.08), 0px 6px 12px -6px rgba(24, 39, 75, 0.06);
  border-radius: 30px;
  padding: 46px 80px 49px 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 40px;
}

.infoPlatform {
  flex-basis: 765px;
}

.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 27px;
  margin-bottom: 39px;
  margin-top: 56px;

  &.link {
    display: block;
    color: $title;
    text-decoration-line: underline;
  }
}

.desc {
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 10px;
}

.messengers {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 20px;
}

.btn {
  width: 264px;
}

.displayNone{
  display: none;
}

.control {
  display: flex;
  align-items: center;
  gap: 20px;
}

.addPlatform {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  color: $title;
  padding: 16px 8px;
  cursor: pointer;
}

.addedPlatform{
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  color: $title;
  padding: 16px 8px;
  cursor: pointer;
}

.disabledAddPlatform {
  pointer-events: none;
  opacity: 0.7;
}

.logoPlatform {
  border: 0.6px solid #bfc1c8;
  border-radius: 15px;
  overflow: hidden;
  width: 211px;
  height: 211px;
  padding: 10px;
  flex-shrink: 0;

  img {
    object-fit: cover;
  }
}

.loader {
  height: 50vh !important;
}

.PlatformDetailsFn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.platformWrap {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(291px, 1fr));
  grid-gap: 25px;
}

.platformWrapPopular {
  margin-bottom: 56px;
}

.addReview {
  padding: 8px 10px 8px 44px;
  font-weight: 500;
  font-size: 22px;
  line-height: 140%;
  color: $title;
  background-image: url('../../assets/img/platforms/Pen.svg');
  background-repeat: no-repeat;
  background-position-x: 6px;
  background-position-y: 10px;
  border-radius: 12px;

  &:hover {
    background-color: $light_gray;
  }

  &:active {
    background-color: $white;
    border: 1px solid $btn_bg;
  }

  &:disabled {
    border: none;
    background-color: $white;
    background-image: url('../../assets/img/platforms/PenDisabled.svg');
    color: $light_border;
  }
}

.reviewsWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 32px;
}

.reviews {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 25px;
}

.reviewForm {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.symbolCount {
  position: absolute;
  top: 170px;
  right: 22px;
  font-weight: 400;
  font-size: 12px;
  line-height: 23px;
  color: $grayDate;
}

.reviewArea {
  width: 100%;
  min-height: 170px;
  padding: 10px 20px;
  border: 1px solid $light_border;
  border-radius: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: $grayDate;
  resize: none;
  outline: none;
}

.reviewbutton {
  display: flex;
  justify-content: center;
  align-self: flex-end;
  padding: 15px 25px;
  margin-top: 44px;
  background: $active;
  border-radius: 14px;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  color: $white;
}

@media (max-width: 1170px) {
  .platformWrap {
    grid-template-columns: repeat(auto-fill, minmax(251px, 1fr));
    grid-gap: 25px 16px;
  }

  .infoPlatform {
    flex-basis: 639px;
  }

  .detailsCard {
    padding: 46px 80px 49px 80px;
  }

  .addPlatform {
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    padding: 16px 0;
  }

  .addedPlatform{
    font-size: 18px;
    line-height: 120%;
    padding: 16px 0;
  }

  .reviews {
    gap: 25px 16px;
  }
}

@media (max-width: 1100px) {
  .infoPlatform {
    flex-basis: 632px;
  }

  .detailsCard {
    padding: 46px 48px;
  }
}

@media (max-width: 1024px) {
  .platformWrap {
    grid-template-columns: repeat(auto-fill, minmax(234px, 1fr));
  }
}

@media (max-width: 930px) {
  .detailsCard {
    padding: 30px 40px;
  }

  .desc {
    font-size: 16px;
    line-height: 20px;
  }

  .messengers {
    font-size: 12px;
    line-height: 16px;
  }

  .addPlatform {
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
  }

   .addedPlatform{
    font-size: 16px;
   }

  .logoPlatform {
    width: 190px;
    height: 190px;
  }

  .btn {
    width: 200px;
    padding: 14px 19px;
  }
}

@media (max-width: 768px) {
  .platformWrap {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    grid-gap: 25px 8px;
  }

  .detailsCard {
    padding: 20px 40px;
  }

  .title {
    font-size: 18px;
  }

  .desc {
    font-size: 14px;
    line-height: 18px;
  }

  .messengers {
    font-size: 12px;
    line-height: 18px;
  }

  .addPlatform {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
  }

  .addedPlatform{
    font-size: 14px;
  }

  .logoPlatform {
    width: 185px;
    height: 185px;
  }

  .btn {
    width: 178px;
    padding: 12px 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
  }

  .reviews {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
  }

  .reviewbutton {
    padding: 20px 15px;
    margin-top: 32px;
    border-radius: 14px;
    font-size: 14px;
  }
}

@media (max-width: 716px) {
  .detailsCard {
    padding: 15px 20px;
  }

  .logoPlatform {
    width: 130px;
    height: 130px;
  }
}

@media (max-width: 620px) {
  .control {
    flex-direction: column;
    align-items: start;
    gap: 0;
  }

  .btn {
    width: 180px;
  }
}

@media (max-width: 560px) {
  .PlatformDetailsFn {
    flex-direction: column;
    gap: 16px;
    margin-bottom: 32px;
  }
}

@media (max-width: 505px) {
  .wrap {
    padding-bottom: 0;
    margin-top: 180px;
  }

  .platformWrap {
    margin-bottom: 0;
  }
}

@media (max-width: 480px) {
  .detailsCard {
    position: relative;
    display: block;
    padding: 16px;
    align-items: start;
    margin-bottom: 20px;
  }

  .platformWrapPopular {
    margin-bottom: 32px;
  }

  .title {
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    margin-bottom: 20px;
  }

  .desc {
    width: 167px;
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 8px;
  }

  .messengers {
    width: 167px;
    font-weight: 400;
    font-size: 9px;
    line-height: 12px;
    margin-bottom: 20px;
  }

  .control {
    align-items: center;
    gap: 8px;
  }

  .logoPlatform {
    position: absolute;
    top: 45px;
    right: 16px;
    width: 76px;
    height: 80px;
  }

  .btn {
    width: 100%;
    padding: 10px 14px;
  }

  .addPlatform {
    gap: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    padding: 8px;
  }

  .addedPlatform{
    gap: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    padding: 8px;
  }

  .reviewsWrapper {
    align-items: center;
    gap: 28px;
  }

  .addReview {
    text-align: center;
    font-size: 16px;
    background-position-y: 6px;
  }

  .reviews {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .reviewArea {
    min-height: 223px;
    padding: 12px;
    border: 1px solid $light_border;
    border-radius: 7px;
  }

  .reviewbutton {
    width: 100%;
    margin-top: 20px;
  }

  .symbolCount {
    top: 220px;
    right: 12px;
  }

  .platformWrap {
    margin-top: 20px;
    gap: 16px;

    grid-template-columns: repeat(auto-fill, minmax(1fr, 1fr));
    grid-gap: 16px;
  }

  .loader {
    height: 100vh !important;
  }
}