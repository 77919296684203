@import '../../../scss/vars';

.accordionSection {
  display: flex;
  flex-direction: column;
  background-color: $backgr_light;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 4px;
}

.accordionTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 18px 29px;
}

.accordionContent {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: start;
  overflow: hidden;
  transition: max-height 0.6s ease;
  max-height: 0;
  padding: 0 29px;
}

.accordionContentInner {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  background-color: $white;
  border-radius: 24px;
  padding: 20px 25px;
  margin: 0 0 29px;
  max-height: 1000px;
  transition: max-height 0.6s ease;
}

.accordionContent.open {
  max-height: 1000px;
}

.accordionTitle {
  color: $text;
  font-size: 24px;
}

.accordionArrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  transition: transform 0.6s;
}

.accordionArrow.rotate {
  transform: rotateX(180deg);
}

@media (max-width: 768px) {
  .accordionTitle {
    font-size: 18px;
  }
}

@media (max-width: 380px) {
  .accordionTitle {
    font-size: 14px;
  }
}
