@import "../../../scss/vars";

.wrapper {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.wrapperEdit {
    margin-bottom: 5px;
}

.textarea {
    border-radius: 15px;
    border: 1px solid $gray_border;
    font-size: 20px;
    outline: none;
    font-weight: 400;
    padding: 15px 20px;
    resize: none;

    &:focus {
        border: 1px solid $btn;
    }

    &::placeholder {
        color: $gray_text;
    }

    @media(max-width: 768px) {
        font-size: 14px;
    }
}

.textareaShort {
    height: 90px;
}

.textareaLong {
    height: 250px;
}

.textareaEdit {
    border: none;
    height: initial;
    padding: 0px 0px 0px 20px;
    margin-bottom: 5px;
    font-weight: 500;

    &:focus {
        border: none;
    }

    @media(max-width: 640px) and (min-width:310px) {
        font-size: 12px;
        padding: 0px 0px 0px 5px;
    }
}