@import '../../../scss/vars';

.wrap {
  display: flex;
  align-items: center;
  gap: 15px;
  background-color: $white;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  border-radius: 12px;
  height: 50px;
  max-width: 524px;
  padding: 13px 23px;
  margin: -25px auto;
  margin-bottom: 16px;
}

.input {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: $black;
  outline: none;
  flex-basis: 450px;
  cursor: text;

  &::placeholder {
    color: #bfc1c8;
  }
}

.btn {
  width: 140px;
}

.close {
  cursor: pointer;
}

@media (max-width: 640px) {
  .wrap {
    max-width: 400px;
    height: 40px;
  }

  .input {
    font-size: 18px;
  }
}

@media (max-width: 481px) {
  .wrap {
    height: 44px;

    svg {
      width: 14px;
      height: 14px;
      margin: 0;
    }
  }

  .input {
    font-size: 14px;
  }
}