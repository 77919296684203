@import "../../../../scss/vars";
.menuWrap {
    height: 720px;
    width: 246px;
    box-sizing: border-box;
    padding: 71px 19px 40px 19px;
    background-color: $title;
    border-radius: 0px 0px 15px 15px;
    position: relative;
    z-index: 2;

    @media (max-width: 480px) {
        width: 320px;
        height: 607px;
        padding: 20px 12px;
    }
}

.logo {
    font-family: Inter;
    font-size: 32px;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    color: $white;
    margin-bottom: 48px;

    @media (max-width: 480px) {
        margin-bottom: 38px;
    }
}

.navLinks {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 80px;

    @media (max-width: 480px) {
        margin-bottom: 54px;
    }
}

.navLink {
    color: $white;
    font-size: 16px;
    font-weight: 500;
    line-height: 36px;
    width: 208px;
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 2px 10px;
    transition: all 0.5s;
    &:hover {
        transition: all 0.5s;
        background-color: $backgr_blue;
    }
}
.navLinkActive {
    color: $title;
    background-color: $white;
    pointer-events: none;
    & svg {
        filter: brightness(0) saturate(100%) invert(12%) sepia(95%) saturate(1380%) hue-rotate(197deg) brightness(97%)
            contrast(104%);
    }
}
.iconsWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
}

.close {
    display: none;

    @media (max-width: 480px) {
        display: block;
        position: absolute;
        top: 25px;
        right: 15px;
    }
}