.contentWrap {
  margin-left: 246px;
  padding: 0 100px 0 65px;
  box-sizing: border-box;

  @media (max-width: 1170px) {
      padding: 0 65px;
  }

  @media (max-width: 1024px) {
      padding: 0 22px 0 35px;
  }

  @media (max-width: 768px) {
      padding: 60px 38px 0;
      margin: 0;
  }

  @media(max-width: 480px) {
      padding: 49px 16px 0;
  }
}