@import '../../../../scss/vars';

.userPanel {
    width: 46px;
    height: 46px;
    color: $light_gray;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    background-color: $bright_border;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 46px;

    @media(max-width: 480px) {
      display: none;
  }
}

.userIcon {
  display: none;

  @media(max-width: 480px) {
      display: block;
  }
}