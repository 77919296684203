@import "../../../../scss/vars";

.wrapper {
    position: fixed;
    overflow: scroll;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(87, 93, 100, 0.07);
    transition: all 0.5s ease-in-out;
}

.hide {
    display: none;
}

.modal {
    max-width: 601px;
    padding: 60px 100px;
    border-radius: 10px;
    background: $white;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 8px 24px -4px rgba(24, 39, 75, 0.08), 0px 6px 12px -6px rgba(24, 39, 75, 0.12);
    position: absolute;
    left: 35%;
    top: 15%;
    z-index: 2;
    transition: all 0.5s ease-in-out;
    overflow: scroll;

    @media (max-width: 1170px) {
        top: 13%;
    }

    @media (max-width: 768px) {
        left: 10%;
    }

    @media (max-width: 480px) {
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        padding: 78px 16px 0;
    }
}

.icon {
    position: absolute;
    top: 30px;
    right: 30px;
    transform: rotate(46.603deg);

    &>svg {
        fill: $btn;
    }
}


.text {
    color: $btn;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    padding-bottom: 60px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.btn {
    margin: 0 auto;
    margin-top: 20px;
    width: 264px;
}