@import '../../../scss/vars';

.searchHistoryContainer {
  height: 146px;
  background-color: $backgr_blue;
  border-radius: 25px;
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  svg {
    width: 25px;
    height: 25px;
    margin-bottom: 11px;
  }

  .searchHistoryTitle {
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 20px;
  }

  .searchHistoryDescription {
    font-size: 10px;
    line-height: 15px;
    font-weight: 400;
    width: 155px;
  }
}

.active {
  background-color: $white;
  color: $title;
}

@media (max-width: 481px) {
  .searchHistoryContainer {
    width: 100%;
    height: 40px !important;
    flex-direction: row !important;
    align-items: center;
    border-radius: 10px !important;
    gap: 12px;
    margin-top: 35px;
    svg {
      margin: 0 !important;
    }

    .searchHistoryTitle {
      margin-bottom: 0 !important;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .searchHistoryDescription {
    display: none;
  }
}

@media only screen and (max-height: 750px) {
  .searchHistoryContainer {
    width: 100%;
    height: 40px !important;
    flex-direction: row !important;
    align-items: center;
    border-radius: 10px !important;
    gap: 12px;
    svg {
      margin: 0 !important;
    }
    .searchHistoryTitle {
      margin-bottom: 0 !important;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .searchHistoryDescription {
    display: none;
  }
}
