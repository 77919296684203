@import "../../../scss/vars";

.possib {
    &__textareaWrapper {
        flex-grow: 1;
    }

    &__textarea {
        width: calc(100% - 2px);
        height: initial;
        border: none;
        outline: none;
        border-radius: 15px;
        background: none;
        padding: 5px;
        font-size: 20px;
        font-weight: 400;
        resize: none;

        @media (max-width:900px) {
            font-size: 18px;
        }

        @media(max-width:525px) {
            font-size: 12px;
        }
    }

    &__textareaEdited {
        color: $grayDate;
        line-height: 30px;
    }
}