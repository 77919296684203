@import '../../../scss/vars';

.wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.error {
  margin-left: 10px;
  color: $error;
  font-size: 11px;
  font-weight: 500;
  line-height: 20px;
}

.message {
  margin-left: 10px;
  color: $btn;
  font-size: 11px;
  font-weight: 500;
  line-height: 20px;
}

.input {
  padding: 10px 10px 10px 23px;
  border-radius: 12px;
  border: 0.6px solid $btn_bg;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12);
  outline: none;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;

  &:focus {
    border: 0.6px solid $btn;
  }
}

.errorInput {
  border: 0.6px solid $error;
}

.placeholder {
  position: absolute;
  bottom: 10px;
  left: 23px;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: $gray_text;
  pointer-events: none;

  span {
    color: $error;
    pointer-events: none;
  }
}

.errorPlaceholder {
  color: $error;
}

.passwordIcon {
  position: absolute;
  bottom: 11px;
  right: 23px;
  font-weight: 400;
  cursor: pointer;
}

.error .e {
  display: none;
}

@media (max-width: 1170px) {
  .error,
  .message {
    font-size: 9px;
    line-height: 14px;
  }
}

@media (max-width: 1024px) {
  .input,
  .placeholder {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .error,
  .message {
    font-size: 8px;
    line-height: 10px;
  }

  .input {
    padding-block: 3px;
    font-size: 12px;
  }

  .placeholder {
    font-size: 12px;
    bottom: 3px;
  }

  .passwordIcon {
    bottom: 7px;
  }
}
