@import "../../../scss/vars";

.wrapper {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(46, 46, 46, 0.8);
}

.hide {
  display: none;
}

.modal {
  position: absolute;
  width: 600px;
  height: 678px;
  top: 120px;
  left: 420px;
  background: $white;
  border-radius: 36px;

  @media (max-width: 1170px) {
    left: 285px;
  }

  @media (max-width: 1024px) {
    left: 212px;
  }

  @media (max-width: 768px) {
    left: 84px;
  }

  @media (max-width: 480px) {
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    border-radius: 0;
  }
}

.icon {
  position: absolute;
  top: 30px;
  right: 30px;
  transform: rotate(46.603deg);

  &>svg {
    fill: $light_border;
  }
}

.title {
  padding: 81px 25px 40px;
  font-size: 28px;
  font-weight: 500;
  line-height: 34px;
  text-align: center;
  color: $btn;
}

.photo {
  height: 265px;
  background: $black;
}

.imgWrapper {
  position: relative;
  width: 305px;
  height: 265px;
  margin: 0 auto;
  overflow: hidden;

  // img {
  //   width: 100%;
  //   height: 100%;
  //   object-fit: cover;
  // }
}

.controls {
  margin: 20px 25px 63px;
  display: flex;
  justify-content: space-between;
}

.controlsBtn {
  // display: flex;
  // vertical-align: middle;
  // align-items: center;
  width: 45px;
  height: 45px;
  border: 0.4px solid $btn_bg;
  border-radius: 100%;
  box-shadow: 0px 8px 24px -4px rgba(24, 39, 75, 0.08), 0px 6px 12px -6px rgba(24, 39, 75, 0.12);
}

.loupes {
  display: flex;
  gap: 24px;
}

.buttons {
  display: flex;
  margin: 0 25px;
  gap: 20px;
}

.btn {
  width: 265px;
  height: 60px;
  border-radius: 14px;
}

.cancel {
  border: 1px solid $red;
  color: $red;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}