@import '../../../../scss/vars';

.searchBar {
  max-width: 524px;
  width: 100%;
  height: 50px;
  border-radius: 12px;
  border: 0.6px solid $btn_bg;
  box-shadow: 0px 8px 24px -4px rgba(24, 39, 75, 0.08), 0px 6px 12px -6px rgba(24, 39, 75, 0.12);
  box-sizing: border-box;
  padding: 13px 23px;
  display: flex;
  align-items: center;
  gap: 14px;
  transition: all 0.5s;

    &:hover {
        border: 0.6px solid $text;
        transition: all 0.5s;
    }

    &:active {
      background-color: $backgr_light_blue;
      transition: background 0.5s;
    }

    @media (max-width: 992px) {
      max-width: 482px;
      margin-left: 100px;
    }
  
    @media (max-width: 768px) {
      margin-left: 50px;
      margin-right: 50px;
    }
  
    @media (max-width: 640px) {
      margin-left: 15px;
      margin-right: 15px;
    }
  
    @media (max-width: 480px) {
      width: 94%;
      margin: 0;
      background: $white;
      padding: 10px 12px;
      gap: 9px;
  
      svg {
        width: 20px;
        height: 20px;
        margin: 0;
      }
    }
}

.input {
    height: 100%;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    outline: none;
    width: 100%;

    @media (max-width: 480px) {
      font-size: 14px;
      font-weight: 600;
      line-height: 36px;
  }

    &::placeholder {
        color: $light_border;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;

        @media (max-width: 480px) {
          font-size: 14px;
          margin: auto 0;
      }
    }
}
