@import '../../../scss/vars';


.advantages__top {
    margin-bottom: 45px;
    @media (max-width: 520px) {
        margin-bottom: 24px;
    }
    h3 {
        color: $backgr_footer;
        text-align: center;
        font-size: 40px;
        font-weight: 400;
        line-height: 96%;
        margin-bottom: 48px;
        @media (max-width: 768px) {
            font-size: 28px;
            margin-bottom: 24px;
        }
        @media (max-width: 520px) {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 24px;
        }
    }
    p {
        color: $backgr_footer;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        margin: auto;
        max-width: 700px;
        @media (max-width: 520px) {
            font-size: 12px;
            line-height: 110%;
        }
    }
}

.advantages__bottom {
    display: flex;
    flex-direction: column;
    background-image: url('./BigDiamond.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 25% 95%;
    position: relative;
    @media (max-width: 850px) {
        background-size: 30% 95%;
    }
    @media (max-width: 660px) {
        background-position: bottom;
        background-size: 150px 360px;
        padding-bottom: 360px;
    }
    @media (max-width: 400px) {
        background-size: 130px 340px;
    }
}

.top_cards {
    display: flex;
    justify-content: space-around;
    margin-bottom: 305px;
    ::before {
        content: '';
        display: block;
        height: 170px;
        width: 85px;
        position: absolute;
        top: 230px;
        left: 25%;
        z-index: 1;
        background-image: url('./BigDiamond.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        @media (max-width: 1024px) {
            top: 180px;
            left: 20%;
        }
        @media (max-width: 768px) {
            height: 110px;
            width: 45px;
            top: 160px;
            left: 20%;
        }
        @media (max-width: 660px) {
            top: 70%;
            left: 130px;
        }
        @media (max-width: 620px) {
            top: 65%;
            left: 15%;
        }
        @media (max-width: 400px) {
            top: 70%;
            left: 8%;
        }

    }
    @media (max-width: 1024px) {
        justify-content: space-between;
        margin-bottom: 200px;
    }
    @media (max-width: 768px) {
        margin-bottom: 100px;
    }
    @media (max-width: 620px) {
        margin: 5px;
    }
}

.bottom_cards {
    display: flex;
    justify-content: space-between;
}

.advantages__cardsWwrap {
    width: 400px;
    margin: 5px;
    padding: 24px 0;
    border-radius: 32px;
    background-color: rgba(9, 78, 145, 1);
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 100;
    :first-child {
        svg {
            width: 120px;
            height: 120px;
            @media (max-width: 1024px) {
                width: 90px;
                height: 90px;
            }
            @media (max-width: 620px) {
                width: 48px;
                height: 48px;
            }
        }
        
    }
    :nth-last-child(1) {
        color: $btn_bg;
        max-width: 216px;
        @media (max-width: 620px) {
            flex: 1 1 auto;
        }
        h4 {
            font-size: 16px;
            font-weight: 600;
            padding-bottom: 16px;
            @media (max-width: 620px) {
                font-size: 12px;
            }
        }
        p {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            @media (max-width: 620px) {
                font-size: 10px;
            }
        }
    }
    @media (max-width: 1024px) {
        width: 350px;
    }
    @media (max-width: 768px) {
        width: 340px;
    }
    @media (max-width: 620px) {
        flex-direction: column;
        padding: 5px 10px 15px 10px;
    }
}