@import '../../scss/vars';

.innerContainer {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.btn {
  width: fit-content;
  margin: 0 auto;
  padding: 16px 24px;
  font-size: 20px;
  margin-top: 30px;
}

@media (max-width: 480px) {
  .text {
    font-size: 12px;
  }
  .btn {
    width: 100%;
    padding: 10px 24px;
    height: 40px;
    font-size: 14px;
    margin-top: 20px;
  }
}
