@import '../../scss/vars';

.wrapper {
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  gap: 35px;
  height: 100vh;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  width: 0;
}

.sidebarContainer {
  height: 100vh;
  padding: 10px 0;
}

.wrapContent {
  width: 100vw;
}

.headerContainer {
  display: flex;
  padding: 54px 0 44px 0;
  justify-content: space-between;
  align-items: center;
}

.inputAccount {
  flex-basis: 524px;
  margin: 0;
}

.userName {
  height: 46px;
  width: 46px;
  background: $bright_border;
  border-radius: 50%;
  color: $light_gray;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin-left: 12px;
}

.controlPanel {
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: auto;
}

.UserPanel {
  position: relative;
  &:hover .control {
    z-index: 1;
    opacity: 100%;
    transform: translateY(0);
    transition: all 0.9s;
  }
}

.icons {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  border-radius: 50%;
}

.noClick {
  pointer-events: none;
}

.burgerBtn {
  display: none;
  cursor: pointer;
  width: 46px;
  height: 46px;
  background-image: url('../../assets/img/burger.svg');
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  position: relative;
}

@media (max-width: 1081px) {
  .wrapper {
    gap: 20px;
  }

  .headerContainer {
    gap: 115px;
  }
}

@media (max-width: 1024px) {
  .wrapper {
    gap: 15px;
  }
}

@media (max-width: 1015px) {
  .headerContainer {
    gap: 40px;
  }
}

@media only screen and (max-width: 768px) {
  .wrapper {
    padding: 0 16px;
  }

  .sidebarContainer {
    display: none;
  }

  .burgerBtn {
    display: flex;
    flex-shrink: 0;
  }

  .sidebarContainerActive {
    display: block;
  }

  .inputAccount {
    flex-basis: 352px;
  }

  .mobileSidebarContainer {
    opacity: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(34, 34, 34, 0.8);
    position: fixed;
    transition: all 0.5s ease-in-out;
    z-index: 99999;
  }
  
  .bgActive {
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }
}

@media (max-width: 320px) {
  .headerContainer {
    flex-wrap: wrap;
  }

  .inputAccount {
    flex-basis: 100%;
    order: 4;
    margin: 0 auto;
  }

  .mobileSidebarContainer {
    opacity: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(34, 34, 34, 0.8);
    position: fixed;
    transition: all 0.5s ease-in-out;
    z-index: 99999;
  }

  .bgActive {
    opacity: 1;
    visibility: visible;
    overflow: scroll;
  }

  .wrapper {
    padding: 0;
  }

  .headerContainer {
    background-color: #032d60;
    border-radius: 0px 0px 30px 30px;
    padding: 20px 16px 18px;
  }

  .burgerBtn {
    width: 24px;
    height: 24px;
  }

  .controlPanel {
    position: static;
    gap: 16px;
  }

  .icons {
    width: 24px;
    height: 24px;
    background-color: #ffffff;

    svg {
      width: 11.48px;
    }
  }
}
