@import '../../../scss/vars';

.button {
  width: 100%;
  border-radius: 14px;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  transition: all 0.5s;

  &:hover {
    cursor: pointer;
    transition: all 0.5s;
  }
}

.primary {
  color: $btn;
  border: 1px solid $btn;
  background-color: #ffffff;
  padding: 16px 24px;
  transition: all 0.5s;

  &:hover {
    background-color: $btn_bg;
    color: $hover;
    transition: all 0.5s;
  }

  &:active {
    background-color: transparent;
    color: $active;
  }

  &:disabled {
    pointer-events: none;
    border: 1px solid $gray_border;
    color: $gray_text;
  }
}

.secondary {
  padding: 16px 37px;
  background-color: $btn;
  color: $white;
  border: none;
  transition: all 0.5s;

  &:hover {
    background-color: $hover;
    transition: all 0.5s;
  }

  &:active {
    background-color: $active;
  }

  &:disabled {
    pointer-events: none;
    color: $gray_text;
    background-color: $disabled;
  }
}

.tertiary {
  width: fit-content;
  padding: 16px 24px;
  background: $btn;
  color: $white;
  border: 1px solid $white;
  transition: all 0.5s;

  &:hover {
    border: 1px solid transparent;
    background: $light_blue;
    transition: all 0.5s;
  }

  &:active {
    background-color: $white;
    color: $btn;
  }

  &:disabled {
    pointer-events: none;
  }
}