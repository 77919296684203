@import "../../../scss/vars";

.wrapper {
    overflow: scroll;
}

.table {
    border-spacing: 0 1.5px;
    margin-bottom: 35px;
    border-collapse: collapse;

    th,
    td {
        text-align: left;
        padding: 10px;
        height: 44px;
        width: 20%;

        &:last-child {
            width: 6%;
        }
    }

    th {
        color: $light_border;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    td {
        color: $text;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
    }
}

.statusWrap {
    border-radius: 30px;
    padding: 0px 10px;
    display: inline-block;
}

.activeRow {
    background-color: $active_row_bg;

    .statusWrap {
        background: $active_status_bg;
        color: $active_status;
    }
}

.blockedRow {
    background-color: $backgr_light_blue;

    .statusWrap {
        background: $block_status;
        color: $active;
    }
}

.activeRow, .blockedRow {
    border-bottom: 1.5px solid $white;

    &:hover {
        border-bottom: 1.5px solid $active;
        cursor: pointer;
    }

    &:active {
        background: $light_blue;
    }
}

.editCell {
    position: relative;

    .editButton {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0;
    }

    .editingActions {
        position: absolute;
        opacity: 0;
        visibility: hidden;
        padding: 13px 29px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        background-color: $white;
        border-radius: 10px;
        z-index: 1;
        box-shadow:
            0px 8px 24px -4px rgba(24, 39, 75, 0.08),
            0px 6px 12px -6px rgba(24, 39, 75, 0.12);
        left: -163px;
        transition:
            opacity 0.5s,
            visibility 0.5s,
            background 0.5s;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 46px;
        display: block;

        &:active {
            background-color: $backgr_light_blue;
            transition: background 0.5s;
        }

        &_visible {
            opacity: 1;
            visibility: visible;
            transition:
                opacity 0.5s,
                visibility 0.5s;
        }

        &_hide {
            opacity: 0;
        }
    }
}

.name {
    width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
