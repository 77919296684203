@import '../../../scss/vars';

.wrapper {
  position: relative;
  min-height: 390px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 26px;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  cursor: pointer;
  overflow: hidden;

  &:active {
    border: 0.6px solid $bright_border;
  }
  &.selected {
    border: 2px solid $bright_border;
  }
}

.container {
  padding: 20px 20px 0px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border: 0.6px solid #bfc1c8;
  border-bottom: none;
  border-radius: 26px 26px 0 0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 5px;
  margin-bottom: 22px;
}

.blackout {
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: $backgr_light;
    opacity: 50%;
    position: absolute;
  }
}

.headerImage {
  height: 60px;
  width: 60px;
  border: 0.6px solid $light_border;
  border-radius: 15px;
  overflow: hidden;
}

.image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.categoriesWrapper {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 3px;
}

.category {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 11px;
  border: 0.6px solid $bright_border;
  border-radius: 15px;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: $bright_border;
  height: 24px;
}

.mainInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: $text;
}

.rating {
  display: flex;
  align-items: center;
  gap: 6.25px;
  font-size: 14px;
  line-height: 22px;
  color: $text;
}

.info {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: $text;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.messengers {
  margin-bottom: 20px;
  margin-top: auto;
  display: flex;
  flex-wrap: wrap;
  font-weight: 500;
  font-size: 10px;
  line-height: 180%;
  color: $text;

  & > span {
    padding-right: 2px;
  }
}

@media (max-width: 1170px) {
  .header {
    margin-bottom: 22px;
  }

  .category {
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
  }

  .info {
    font-size: 14px;
    line-height: 130%;
    -webkit-line-clamp: 4;
  }
}

@media (max-width: 1024px) {
  .wrapper {
    min-height: 370px;
  }

  .category {
    font-size: 9px;
  }

  .info {
    font-size: 12px;
    line-height: 167%;
  }

  .messengers {
    margin-bottom: 10px;
  }
}

@media (max-width: 1024px) {
  .wrapper {
    min-height: 390px;
  }
}

@media (max-width: 480px) {
  .info {
    font-size: 14px;
  }
}
