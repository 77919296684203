.select {
    input {
        width: 100%;
        padding: 0px 10px;
        background-color: #fff;
        border: 1px solid #E6EDF3;
        border-radius: 12px;
        font-weight: 400;
        font-family: 'Montserrat';
        font-size: 15px;
        line-height: 22px;
        color: #121212;
        outline: none;
        height: 55px;
    }
}

