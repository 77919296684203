@import '../../../scss//vars';

.templates_container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  background: $white;
  min-height: 100vh;
  width: 100%;
}

.templates_wrapper {
  width: 90%;
  max-width: 1240px;
  margin: auto;
  padding: 50px 0;
  h1 {
    font-size: 30px;
    line-height: 36px;
    color: $btn;
  }
}

.templates_header {
  display: flex;
  justify-content: space-between;
  h1 {
    font-size: 30px;
    line-height: 36px;
    color: $btn;
  }
}

.platformCreation {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  background: $white;
  min-height: 100vh;
  width: 100%;

  @media (max-width: 540px) {
    position: static;
  }

  &__template {
    max-width: 1280px;
    padding: 68px 20px 130px;
    margin: 0 auto;

    @media (max-width: 1439.5px) {
      max-width: 1040px;
    }

    @media (max-width: 1024px) {
      padding: 53px 20px 130px;
    }

    @media (max-width: 540px) {
      padding: 0px 0px 8px;
    }
  }

  &__btnsWrap {
    display: flex;
    gap: 25px;
    justify-content: flex-end;
    width: 100%;
    padding: 11px 46px;
    background: $backgr_pattens_blue;

    @media (max-width: 540px) {
      background: none;
      padding: 0px 16px 54px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

.template {
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 42px;

    @media (max-width: 540px) {
      margin-bottom: 28px;
    }
  }

  &__title {
    font-size: 30px;
    color: $admin_platform_title;
    font-weight: 500;

    @media (max-width: 1024px) {
      font-size: 28px;
    }

    @media (max-width: 540px) {
      font-size: 16px;
    }
  }

  &__iconWrap {
    @media (max-width: 540px) {
      display: none;
    }
  }
}

.solutions__title {
  font-size: 24px;
  font-weight: 500;
  color: $text;
  margin-bottom: 32px;

  @media (max-width: 1024px) {
    font-size: 20px;
    margin-bottom: 20px;
  }

  @media (max-width: 540px) {
    font-size: 16px;
  }
}

.btnsWrap {
  &__btnWrap {
    width: 180px;

    @media (max-width: 540px) {
      width: 288px;
    }
  }
}

.btnPrimary {
  background: none;
}
