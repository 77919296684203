@import '../../../scss/vars';

.layot {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px 10px;
  margin-top: 24px;
}

.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}

.item {
  display: flex;
  align-items: center;
  gap: 20px;
  transition: all 0.5s;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  &:hover {
    transition: all 0.5s;
    color: $hover;
    cursor: pointer;
  }
}

.wrapCard {
  padding: 15px 21px;
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: 90px;
  border: 0.6px solid #bfc1c8;
  border-radius: 20px;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
}

.images {
  width: 60px;
  height: 60px;
  border: 0.6px solid #bfc1c8;
  border-radius: 15px;
  overflow: hidden;
  img {
    object-fit: cover;
  }
}

.setings {
  display: none;
}

@media (max-width: 1435px) {
  .wrapCard {
    padding: 15px 20px;
    gap: 14px;
    height: 90px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
  }
}

@media (max-width: 1160px) {
  .wrapCard {
    padding: 15px 20px;
    gap: 14px;
    height: 90px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
}

@media (max-width: 1130px) {
  .item {
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
  }
}

@media (max-width: 1024px) {
  .layot {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px 20px;
  }
  .wrapCard {
    padding: 15px 20px;
    gap: 14px;
    width: 100%;
    height: 90px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
}

@media (max-width: 885px) {
  .item {
    font-weight: 400;
    font-size: 10px;
    line-height: 21px;
  }
}

@media (max-width: 765px) {
  .wrap {
    transition: all 0.7s;
    opacity: 0;
    z-index: -1;
    position: absolute;
    flex-direction: column;
    align-items: flex-start;
    width: 175px;
    height: 258px;
    background: #ffffff;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    border-radius: 10px;
    padding: 12px;
    gap: 15px;
  }
  .item {
    font-weight: 400;
    font-size: 10px;
    line-height: 21px;
    gap: 8px;
  }
  .setings {
    cursor: pointer;
    display: block;
  }

  .wrapActive {
    transition: all 0.7s;
    opacity: 1;
    z-index: 5;
  }
}

@media (max-width: 635px) {
  .layot {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 20px;
    margin-top: 24px;
  }
}

@media (max-width: 430px) {
  .layot {
    grid-template-columns: repeat(1, 1fr);
    gap: 16px 16px;
    margin-top: 24px;
  }
}
