.title {
  text-align: center;
  margin-bottom: 40px;
}

.comtainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
