@import '../../scss/vars';

.detailsCard {
  color: $text;
  background-color: $white;
  border: 0.5px solid $light_border;
  box-shadow: 0px 8px 24px -4px rgba(24, 39, 75, 0.08), 0px 6px 12px -6px rgba(24, 39, 75, 0.06);
  border-radius: 30px;
  padding: 40px 80px 40px 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 40px;
}

.infoPlatform {
  flex-basis: 765px;
}

.title {
  font-weight: 500;
  font-size: 28px;
  line-height: 35px;
  margin-bottom: 39px;
  color: $title;
}

.desc {
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  margin-bottom: 30px;

  span {
    color: $title;
  }
}

.btn {
  width: 264px;
}

.control {
  display: flex;
  align-items: center;
  gap: 20px;
}

.addPlatform {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  color: $title;
  padding: 16px 8px;
  cursor: pointer;
}

.removePlatform {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  color: $title;
  padding: 16px 8px;
  cursor: pointer;
}

.logoPlatform {
  border: 0.6px solid #bfc1c8;
  border-radius: 15px;
  overflow: hidden;
  width: 211px;
  height: 211px;
  padding: 10px;
  flex-shrink: 0;

  img {
    object-fit: cover;
  }
}

.noLogo {
  width: 100%;
  height: 100%;
  background-color: #f1f1f1;
  border-radius: 15px;
}

.titles {
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 40px;
}

.templatesDesc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.templatesDescCard {
  padding: 26px 20px 24px 41px;
  border-radius: 30px;
  border: 0.5px solid $light_border;
  background: $white;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  color: $title;
  flex-basis: 608px;
  margin-bottom: 40px;
  height: 190px;

  span {
    color: $total_black;
    font-weight: 400;
  }
}

.task {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(396px, 1fr));
  gap: 20px 25px;
  margin-bottom: 40px;
}

.taskWrap {
  border-radius: 20px;
  background: rgba(192, 191, 191, 0.22);
  padding: 20px;
}

.taskIcon {
  width: 85px;
  height: 85px;
  border-radius: 25px;
  background: #fff;
  box-shadow: 0px 3px 1px 0px rgba(0, 0, 0, 0.04), 0px 3px 8px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.taskText {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

.events {
  border-radius: 50px;
  background: #f1f3f6;
  box-shadow: 0px 8px 24px -4px rgba(24, 39, 75, 0.08), 0px 6px 12px -6px rgba(24, 39, 75, 0.12);
  padding: 70px 80px 70px 105px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px 52px;
  margin-bottom: 40px;
}

.eventsItem {
  display: flex;
  align-items: flex-start;
  gap: 25px;
}

.eventsIcon {
  width: 81px;
  height: 80px;
  border-radius: 25px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $text;
  font-size: 40px;
  font-weight: 400;
}

.eventsText {
  color: $text;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
}

.loader {
  height: 50vh !important;
}

.price {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 26px;
}

.priceCard {
  min-height: 380px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 527px;
  padding: 40px 52px 0px;
  border-radius: 50px;
  background: #f1f3f6;
  box-shadow: 0px 8px 24px -4px rgba(24, 39, 75, 0.08), 0px 6px 12px -6px rgba(24, 39, 75, 0.12);
}

.nextPriceCard {
  padding-top: 20px;

  & .priceText {
    margin-bottom: 15px;
    text-align: center;
  }

  p {
    font-size: 20px;
    color: $light_border;
  }
}

.priceNum {
  width: 100%;
  font-size: 40px;
  font-weight: 400;
  line-height: 120%;
  color: $deep_purple;

  span {
    color: $light_border;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    margin-left: 25px;
  }
}

.innerBtn {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 29px;
}

.priceText {
  color: $deep_purple;
  font-size: 30px;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 37px;
}

.search {
  display: none;
}

@media (max-width: 1390px) {
  .platform {
    width: 251px;
  }
}

@media (max-width: 1170px) {
  .infoPlatform {
    flex-basis: 680px;
  }

  .desc {
    margin-bottom: 15px;
  }

  .detailsCard {
    padding: 46px 80px 49px 80px;
    margin-bottom: 38px;
  }

  .addPlatform {
    font-size: 18px;
    line-height: 120%;
    padding: 16px 0;
  }

  .removePlatform {
    font-size: 18px;
    padding: 16px 8px;
  }

  .reviews {
    gap: 25px 16px;
  }

  .btn {
    width: 262px;
    padding: 12px 19px;
  }

  .task {
    gap: 20px 16px;
  }

  .events {
    padding: 50px 89px 50px 89px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px 52px;
    margin-bottom: 40px;
  }

  .eventsItem {
    gap: 17px;
  }

  .eventsIcon {
    width: 72px;
    height: 70px;
    font-size: 38px;
  }

  .eventsText {
    font-size: 18px;
    line-height: 27px;
  }

  .templatesDescCard {
    padding: 26px 16px 24px 35px;
    font-size: 20px;
    flex-basis: 608px;
  }
}

@media (max-width: 1140px) {
  .platform {
    width: 234px;
  }

  .title {
    font-size: 26px;
  }

  .desc {
    font-size: 20px;
  }

  .logoPlatform {
    width: 207px;
    height: 211px;
  }

  .titles {
    font-size: 30px;
  }

  .taskText {
    font-size: 16px;
  }

  .price {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 19px;
  }

  .events {
    padding: 42px 64px 42px 40px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 46px 16px;
    margin-bottom: 40px;
  }

  .eventsItem {
    gap: 17px;
  }

  .eventsIcon {
    width: 69px;
    height: 71px;
    font-size: 36px;
  }

  .eventsText {
    font-size: 16px;
    line-height: 25px;
  }

  .templatesDescCard {
    padding: 26px 5px 23px 32px;
    font-size: 19px;
  }
}

@media (max-width: 1100px) {
  .infoPlatform {
    flex-basis: 632px;
  }

  .detailsCard {
    padding: 46px 48px;
  }
}

@media (max-width: 1024px) {
  .price {
    justify-content: center;
  }

  .priceCard {
    min-height: 320px;
    padding: 30px 40px 0px;
    flex-basis: 418px;
  }

  .nextPriceCard {
    padding-top: 25px;

    & .priceText {
      font-size: 22px;
    }

    & p {
      font-size: 16px;
    }
  }

  .priceText {
    font-size: 25px;
  }

  .innerBtn {
    margin-bottom: 28px;

    & button {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .priceNum {
    font-size: 32px;

    & span {
      margin-left: 5px;
      font-size: 16px;
    }
  }
}

@media (max-width: 991px) {
  .platformWrap {
    justify-content: space-evenly;
  }

  .platform:last-child {
    display: none;
  }

  .platformAllRes:nth-last-child(2) {
    display: none;
  }

  .templatesDescCard {
    font-size: 16px;
  }
}

@media (max-width: 930px) {
  .detailsCard {
    padding: 30px 40px;
  }

  .desc {
    font-size: 16px;
    line-height: 20px;
  }

  .messengers {
    font-size: 12px;
    line-height: 16px;
  }

  .addPlatform {
    font-size: 16px;
    line-height: 120%;
  }

  .logoPlatform {
    width: 190px;
    height: 190px;
  }

  .btn {
    width: 200px;
    padding: 14px 19px;
  }
}

@media (max-width: 768px) {
  .detailsCard {
    padding: 20px 40px;
    margin-bottom: 10px;
  }

  .title {
    font-size: 18px;
    margin-bottom: 10px;
    line-height: 21px;
  }

  .desc {
    font-size: 14px;
    line-height: 18px;
  }

  .messengers {
    font-size: 12px;
    line-height: 18px;
  }

  .addPlatform {
    font-size: 14px;
    line-height: 140%;
  }

  .removePlatform {
    font-size: 14px;
    line-height: 140%;
  }

  .logoPlatform {
    width: 152px;
    height: 152px;
  }

  .btn {
    width: 178px;
    padding: 12px 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
  }

  .reviews {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
  }

  .reviewbutton {
    padding: 20px 15px;
    margin-top: 32px;
    border-radius: 14px;
    font-size: 14px;
  }

  .titles {
    font-size: 18px;
  }

  .taskIcon {
    width: 36px;
    height: 36px;
  }

  .taskText {
    font-size: 12px;
    line-height: 13px;
  }

  .taskWrap {
    padding: 10px 20px;
  }

  .task {
    gap: 10px 5px;
  }

  .events {
    padding: 54px 40px 54px 39px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px 16px;
    margin-bottom: 40px;
  }

  .eventsItem {
    gap: 20px;
  }

  .eventsIcon {
    width: 47px;
    height: 45px;
    font-size: 24px;
  }

  .eventsText {
    font-size: 12px;
    line-height: 16px;
  }

  .templatesDescCard {
    padding: 14px 30px 13px 24px;
    font-size: 12px;
    line-height: 16px;
    height: 101px;
    margin-bottom: 10px;
  }

  .price {
    gap: 32px;
  }

  .priceCard {
    min-height: 268px;
    padding: 20px 30px 0px;
    flex-basis: 348px;
  }

  .nextPriceCard {
    padding-top: 16px;

    & .priceText {
      margin-bottom: 14px;
    }

    & p {
      font-size: 12px;
    }
  }

  .innerBtn {
    margin-bottom: 16px;
    gap: 18px;

    & button {
      height: 40px;
      font-size: 14px;
    }
  }

  .priceNum {
    font-size: 26px;

    span {
      font-size: 12px;
      line-height: 20px;
    }
  }
}

@media (max-width: 745px) {
  .platform:last-child {
    display: block;
  }

  .platformAllRes:nth-last-child(2) {
    display: block;
  }

  .price {
    flex-wrap: wrap;
  }
}

@media (max-width: 716px) {
  .detailsCard {
    padding: 15px 20px;
  }

  .logoPlatform {
    width: 130px;
    height: 130px;
  }
}

@media (max-width: 620px) {
  .control {
    flex-direction: column;
    align-items: start;
    gap: 0;
  }

  .btn {
    width: 100%;
    margin-top: 15px;
  }

  .templatesDesc {
    display: block;
  }
}

@media (max-width: 560px) {
  .PlatformDetailsFn {
    flex-direction: column;
    gap: 16px;
    margin-bottom: 32px;
  }

  .events {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 16px 16px;
    margin-bottom: 40px;
  }

  .eventsItem {
    gap: 20px;
    align-items: center;
    border-radius: 10px;
    background: #f1f3f6;
    box-shadow: 0px 8px 24px -4px rgba(24, 39, 75, 0.08), 0px 6px 12px -6px rgba(24, 39, 75, 0.12);
    padding: 10px;
  }

  .eventsIcon {
    width: 47px;
    height: 45px;
    font-size: 24px;
  }

  .eventsText {
    font-size: 12px;
    line-height: 16px;
  }
}

@media (max-width: 505px) {
  .wrap {
    padding-bottom: 0;
  }

  .platformWrap {
    margin-bottom: 0;
  }

  .platform {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .detailsCard {
    position: relative;
    display: block;
    padding: 16px;
    align-items: start;
    margin-bottom: 20px;
    margin-top: 180px;
  }

  .platformWrapPopular {
    margin-bottom: 32px;
  }

  .title {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 20px;
  }

  .titles {
    font-size: 14px;
    line-height: 27px;
    margin-bottom: 8px;
  }

  .desc {
    width: 167px;
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 15px;
  }

  .messengers {
    width: 167px;
    font-weight: 400;
    font-size: 9px;
    line-height: 12px;
    margin-bottom: 20px;
  }

  .control {
    align-items: center;
    gap: 8px;
  }

  .logoPlatform {
    position: absolute;
    top: 45px;
    right: 16px;
    width: 76px;
    height: 80px;
  }

  .btnPrice {
    width: 100%;
    padding: 9px 14px;
  }

  .addPlatform {
    gap: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    padding: 8px;
  }

  .removePlatform {
    gap: 10px;
    font-size: 14px;
    line-height: 140%;
    padding: 8px;
  }

  .reviewsWrapper {
    align-items: center;
    gap: 28px;
  }

  .addReview {
    text-align: center;
    font-size: 16px;
    background-position-y: 6px;
  }

  .reviews {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .reviewArea {
    min-height: 223px;
    padding: 12px;
    border: 1px solid $light_border;
    border-radius: 7px;
  }

  .reviewbutton {
    width: 100%;
    margin-top: 20px;
  }

  .symbolCount {
    top: 188px;
    right: 12px;
  }

  .platformWrap {
    margin-top: 20px;
    gap: 16px;
  }

  .price {
    gap: 16px;
  }

  .priceCard {
    padding: 33px 20px 20px 20px;
    height: 232px;
  }

  .priceNum {
    font-size: 22px;
    margin-bottom: 11px;

    span {
      font-size: 12px;
      line-height: 28px;
    }
  }

  .innerBtn {
    gap: 20px;
    margin-bottom: 10px;
  }

  .task {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  .templatesDescCard {
    padding: 14px 25px 13px 20px;
    font-size: 10px;
    line-height: 16px;
    height: 96px;
    margin-bottom: 10px;
  }
}
