@import "../../../scss/vars";


.template {
    &__roundTitle {
        position: relative;
        transform: translateX(-20px);
        width: 150px;
        border: 1px solid $title;
        border-radius: 15px;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        color: $title;
        padding: 5px 0px;
        margin-bottom: 18px;

        @media (max-width:640px) {
            width: 120px;
            font-size: 12px;
            padding: 2px 0px;
            transform: translateX(0px);
        }
    }

    &__roundTitleEdited {
        transform: translateX(0px);
    }
}