@import '../../../scss/vars';

.wrap {
  border: 0.6px solid #bfc1c8;
  border-radius: 20px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  text-align: start;
  height: 100%;

  &:active {
    border: 0.6px solid $bright_border;
  }
}

.innerImg {
  height: 136px;
  width: 100%;
  background-color: #d9d9d9;
  border-radius: 20px;
  margin-bottom: 18px;
}

.hiddenLike {
  display: none;
}

.content {
  width: 100%;
  padding: 0 17px 16px 16px;
}

.controlPanel {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.title {
  color: $text;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
}

.desc {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  line-height: 24px;
  margin-bottom: 10px;
}

.rating {
  display: flex;
  align-items: center;
  gap: 6.25px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: $text;
}

.btn {
  font-size: 16px;
  line-height: 30px;
  padding: 8px 24px;
  margin-bottom: 6px;

  &:last-child {
    margin-bottom: 0;
  }
}

.icon {
  cursor: pointer;
}

@media (max-width: 1081px) {
  .title {
    font-size: 12px;
  }
}

@media (max-width: 1015px) {
  .desc {
    font-size: 10px;
  }
}

@media (max-width: 767px) {
  .title {
    font-size: 14px;
  }
}

@media (max-width: 560px) {
  .desc {
    font-size: 10px;
  }

  .title {
    font-size: 12px;
  }
}

@media (max-width: 481px) {
  .wrap {
    width: 100%;
  }

  .desc {
    font-size: 12px;
  }

  .title {
    font-size: 14px;
  }
}