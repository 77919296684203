$text: #121212;
$text_basic: #2d2d2d;

$button_1: #26489c;
$button_disabled: #d9d8d8;
$placeh_ok: #cdd8f2;
$border: #2d2d2d;
$text_basic: #2d2d2d;
$btn_hover: #434242;
$black: #121212;
$text2: #f7f5f5;
$total_black: #000000;
$blue: #87b0ce;
// $light_blue: #b9dcf0;
$gray: #5e5f60;

//ниже пишем цвета с нового дизайна
$btn: #094e91;
$btn_bg: #e6edf3;
$hover: #186cbd;
$active: #094e91;
$disabled: #f5f5f5;
$title: #032d60;
$admin_platform_title: #094e91;
$text: #121212;
$light_border: #bfc1c8;
$bright_border: #0176d3;
$gray_border: #9da0a9;
$white: #ffffff;
$backgr_blue: #2c4f7e;
$backgr_light: #f1f3f6;
$backgr_pattens_blue: #c8ebff;
$backgr_shadow: #57575780;
$backgr_light_gray: #f9fafe;
$light_gray: #f6f5f5;
$backgr_light_blue: #eff6ff;
$grayDate: #9da0a9;
$error: #ed0006;
$backgr_white: #f6f5f5;
$gray_text: #9ea1aa;
$btn_disabled: #eff0f4;
$light_blue: #648fba;
$red: #e21c1c;
$light_white: #e3f4fe;
$active_row_bg: #e7fbd8;
$active_status_bg: #b3f87e;
$active_status: #377c00;
$block_status: #d1e6fb;
$account_icons: #a19e9e;
$dark_text: #161616;
$shadow_gold: #eae247;
$light_gray_blue: #dce3e9;
$deep_purple: #2b308b;

//шрифты

$font-family: 'Montserrat', sans-serif;
$second-family: 'Inter', sans-serif;

$backgr_footer: #01142c;
$text_footer: #e6edf3;
$backgr_review_section: #e6edf3;
