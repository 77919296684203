.naw {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 30px;
}

.nawItem {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: #bfc1c8;
  cursor: pointer;
  transition: all 0.9s;
  &:hover {
    transition: all 0.9s;
    color: #032d60;
  }
}

.active {
  color: #032d60;
  font-weight: 500;
}

@media (max-width: 1170px) {
  .nawItem {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 1024px) {
  .nawItem {
    font-size: 18px;
    line-height: 25px;
  }
}

@media (max-width: 870px) {
  .nawItem {
    font-size: 14px;
    line-height: 19px;
  }
}

@media (max-width: 768px) {
  .naw {
    min-width: 100%;
    overflow-x: auto;
    gap: 28px;
    margin-bottom: 19px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .nawItem {
    flex-shrink: 0;
  }
}

@media (max-width: 480px) {
  .nawItem {
    font-size: 12px;
    line-height: 16px;
  }
}
