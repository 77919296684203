.wrap {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  margin-top: 35px;

  @media (max-width: 768px) {
    margin-top: 0;
  }
}

.btn {
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
  padding: 2px 10px;
}
