.form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formTitle {
    font-size: 28px;
    font-weight: 500;
    line-height: 27px;
    margin-bottom: 60px;
    text-align: center;
}

.formText {
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    margin-bottom: 50px;
    text-align: center;
}

.inputsWrap {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 50px;
    width: 100%;
    max-width: 633px;
}

.btn {
    width: 100%;
    max-width: 633px;
    font-size: 18px;
    height: 55px;
    padding: 0px;
    border-radius: 10px;
}

@media (max-width: 480px) {
    .formTitle {
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 30px;
    }

    .formText {
        font-size: 12px;
    }

    .btn {
        font-size: 14px;
        height: 40px;
    }
}
