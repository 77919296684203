@import "../../../scss/vars";

.title {
    color: $text;
    font-size: 28px;
    font-weight: 500;
    line-height: 36px;
    margin-bottom: 48px;

    @media (max-width: 1170px) {
        margin-bottom: 35px;
    }
}

.createButton {
    border-radius: 12px;
    border: 1px solid $btn;
    color: $btn;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    display: flex;
    padding: 10px 20px;
    gap: 15px;
    align-items: center;
    width: 162px;
    margin-bottom: 35px;

    &:hover:not(:active) {
        font-weight: 500;
    }

    &:active {
        background: $backgr_light_blue;
    }

    @media (max-width: 1170px) {
        margin-bottom: 18px;
    }

    @media (max-width: 480px) {
        width: 288px;
        justify-content: center;
    }
}

.icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
        fill: $btn;
    }
}