@import '../../../../scss/vars';

.wrap {
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  border: 0.5px solid $light_border;
  padding: 44px 80px 40px;
  box-shadow: 0px 8px 24px -4px rgba(24, 39, 75, 0.08), 0px 6px 12px -6px rgba(24, 39, 75, 0.12);
}

.title {
  color: $title;
  font-size: 20px;
  margin-bottom: 32px;
}

.select {
  display: flex;
  align-items: center;
  gap: 10px;

  input[type='checkbox'] {
    height: 24px;
    width: 24px;
    appearance: none;
    border: 1px solid #000000;
    padding: 9px;
    border-radius: 3px;
    display: inline-block;
    position: relative;
  }

  input[type='checkbox']:checked:after {
    content: '\2714';
    font-size: 18px;
    position: absolute;
    top: 0px;
    left: 4px;
  }
}

.selectIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectText {
  font-size: 16px;
}

@media (max-width: 1170px) {
  .wrap {
    padding: 40px;
  }
}

@media (max-width: 1024px) {
  .wrap {
    padding: 40px 30px;
  }
}

@media (max-width: 768px) {
  .wrap {
    border: none;
    box-shadow: none;
    padding: 0;
  }

  .title {
    margin-bottom: 30px;
  }
}

@media (max-width: 380px) {
  .title {
    font-size: 14px;
    margin-bottom: 12px;
  }

  .selectText {
    font-size: 14px;
  }
}
