@import "../../../../scss/vars";

.template {
    &__section {
        display: flex;
        /* align-items: center; */
        gap: 8px;
        cursor: pointer;

        @media(max-width:640px) {
            padding: 0;
            gap: 4px;
        }

        & svg {
            width: 24px;
            height: 24px;
            fill: none;
        }

        @media(max-width:1024px) {
            & svg {
                width: 20px;
                height: 20px;
            }
        }

        @media(max-width:640px) {
            & svg {
                width: 18px;
                height: 18px;
            }
        }

        & svg path {
            stroke: $title;
            transition: all 0.5s;
        }

        & p {
            color: $title;
            transition: all 0.5s;
        }

        &:hover svg path {
            stroke: $hover;
        }

        &:hover p {
            color: $hover;
        }
    }

    &__sectionPlusFirst {
        margin-bottom: 16px;
    }
}

.section {
    &__title {
        font-size: 20px;
        font-weight: 400;

        &:active {
            font-weight: 500;
        }

        @media (max-width:1023.5px) {
            font-size: 14px;
        }
    }

    &__titlePlusFirst {
        @media (max-width:1024px) {
            font-size: 18px;
        }

        @media (max-width:640px) {
            font-size: 14px;
        }
    }
}

.modalTag {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    opacity: 1;
    background: $backgr_shadow;
    z-index: 6;

    &__content {
        border-radius: 12px;
        width: 844px;
        background: $white;
        padding: 30px 25px;
        margin: 0 15px;

        @media (max-width:1024px) {
            width: 577px;
        }

        @media (max-width:768px) {
            width: 288px;
            padding: 30px 15px;
        }
    }

    &__contentLarge {
        width: 1040px;
        max-height: calc(100vh - 30px);
        overflow-y: scroll;
        padding: 40px 80px 90px;

        @media (max-width:1170px) {
            width: 934px;
            padding: 40px 20px 90px;
        }

        @media (max-width:1024px) {
            width: 683px;
            padding: 40px 40px 90px;
        }

        @media (max-width:768px) {
            width: 100%;
            padding: 40px 15px 120px;
        }
    }

    &__input {
        display: block;
        width: 330px;
        margin: 0 auto 40px;
        border: 0.6px solid $btn_bg;
        padding: 12px;
        font-size: 14px;
        border-radius: 12px;
        box-shadow: 0px 6px 12px -6px #18274B1F;

        &::placeholder {
            color: #9DA0A9;
        }

        &:focus-visible {
            outline: none;
        }

        @media (max-width:768px) {
            width: 100%;
        }
    }
}

.modalTagClosed {
    display: none;
}

.modalTitle {
    font-size: 30px;
    color: $admin_platform_title;
    font-weight: 500;
    margin-bottom: 20px;

    @media (max-width:640px) {
        font-size: 16px;
    }
}

.btnsWrap {
    width: fit-content;
    margin: 0 auto;
    display: flex;
    gap: 30px;

    @media (max-width:768px) {
        width: 100%;
        flex-direction: column;
        gap: 15px;
    }
}

.btnsWrapRighted {
    margin: 0 0 0 auto;

    @media (max-width:768px) {
        width: fit-content;
        flex-direction: row;
        margin: 0 0 0 auto;
        display: flex;
        gap: 30px;
    }

    @media (max-width:650px) {
        width: 100%;
        flex-direction: column;
        gap: 15px;
    }
}

.btnWrap {
    width: 180px;

    @media (max-width:768px) {
        width: 100%;
    }
}

.btnWrapRighted {
    @media (max-width:768px) {
        width: 180px;
    }

    @media (max-width:650px) {
        width: 100%;
    }
}

.filterParamWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px 5px;
    margin-bottom: 90px;

    &:last-of-type {
        margin-bottom: 16px;
    }

    @media(max-width:767.5px) {
        gap: 20px;
        margin-bottom: 120px;
    }
}

.filterParam {
    position: relative;
    display: flex;
    gap: 16px;
    align-items: center;
    border-radius: 20px;
    padding: 15px 21px;
    border: 0.6px solid $light_border;
    width: 285px;
    transition: all 0.5s;

    &:hover {
        border: 0.6px solid $bright_border;
    }

    @media(max-width:370px) {
        padding: 5px 21px;
        width: 100%;
    }
}

.filterParamChosen {
    border: 0.6px solid $bright_border;
}

.filterParamImgWrap {
    /*     display: flex;
      justify-content: center;
      align-items: center; */
    padding: 10px;
    overflow: hidden;
    width: 60px;
    height: 60px;
    min-width: 60px;
    border-radius: 15px;
    border: 0.6px solid $light_border;

    & img {
        width: 100%;
        height: 100%;
    }

    @media(max-width:345px) {
        width: 53px;
        height: 53px;
        min-width: 53px;
    }
}