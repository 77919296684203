@import "../../../scss/vars";

.wrapper {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.error {
    color: $error;
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;
    margin-bottom: 9px;
}

.input {
    border-radius: 10px;
    border: 1px solid $btn;
    font-size: 18px;
    outline: none;
    font-weight: 400;
    line-height: 25px;
    padding: 14px 20px;
    height: 55px;

    &:focus {
        border: 1px solid $btn;
    }
}

.filledInput {
    cursor: not-allowed;
    color: $gray_text;
}

.errorInput {
    border: 1px solid $error;
}

.placeholder {
    position: absolute;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    color: $gray_text;
    pointer-events: none;
    bottom: 14px;
    left: 20px;
    width: 90%;

    span {
        color: $error;
        pointer-events: none;
    }
}

.errorPlaceholder {
    color: $error;
}

@media (max-width: 768px) {
    .linkPlaceholder {
        line-height: 20px;
        bottom: 8px;
    }

    .error {
        font-size: 13px;
    }
}

@media (max-width: 480px) {
    .input {
        font-size: 12px;
        line-height: 17px;
        height: 40px;
    }

    .placeholder {
        font-size: 12px;
        line-height: 17px;
        bottom: 11px;
        width: 75%;
    }

    .linkPlaceholder {
        bottom: 3px;
    }

    .error {
        font-size: 8px;
        line-height: 10px;
        margin-bottom: 7px;
    }
}
