@import '../../scss/vars';
.inner {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.text {
  font-size: 16px;
  color: #9ea1aa;
  text-align: center;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 22px;
}

.innerForm {
  display: flex;
  flex-direction: column;
  gap: 44px;
}

.btn {
  width: 264px;
  margin: 0 auto;
  padding: 16px;
  font-size: 20px;
}

@media (max-width: 480px) {
  .text {
    font-size: 12px;
  }
  .btn {
    width: 100%;
    padding: 10px 24px;
    height: 40px;
    font-size: 14px;
    margin-top: 20px;
  }
  .innerForm {
    gap: 20px;
  }
}
