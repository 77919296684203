@import '../../scss/vars';

.innerContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: 480px) {
    gap: 14px;
  }
}


.btn {
  width: 264px;
  padding: 16px 24px;
  font-size: 20px;
  margin: 30px 0 0 52px;

  @media (max-width: 480px) {
    width: 100%;
    padding: 10px 24px;
    height: 40px;
    font-size: 14px;
    margin: 20px 0 52px;
  }
}

.row {
  display: flex;
  align-items: center;
  gap: 32px;

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
  }
}

.indent {
  margin-left: 54px;

  @media (max-width: 1124px) {
    margin-left: 64px;
  }

  @media (max-width: 480px) {
    margin-left: 0;
  }
}

.title {
  width: 215px;
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
  color: $title;
  flex-shrink: 0;

  @media (max-width: 620px) {
    width: 70px;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
}

.text {
  @media (max-width: 480px) {
    font-size: 12px;
  }
}

.icons {
  @media (max-width: 480px) {
    display: none;
  }
}