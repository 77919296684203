@import '../../../scss/vars';

.platformCreation {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    background: $white;
    min-height: 100vh;
    width: 100%;

    @media(max-width:640px) {
        position: static;
    }

    &__template {
        max-width: 1280px;
        padding: 68px 15px 130px;
        margin: 0 auto;

        @media (max-width:1439.5px) {
            max-width: 1080px;
        }

        @media (max-width:1024px) {
            padding: 53px 15px 130px;
        }

        @media(max-width:640px) {
            padding: 0px 0px 8px;
        }
    }

    &__btnsWrap {
        display: flex;
        gap: 25px;
        justify-content: flex-end;
        width: 100%;
        padding: 11px 46px;
        background: $backgr_pattens_blue;

        @media(max-width:640px) {
            background: none;
            padding: 0px 16px 16px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
}

.template {
    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 42px;

        @media(max-width:640px) {
            margin-bottom: 20px;
        }
    }

    &__title {
        font-size: 30px;
        color: $admin_platform_title;
        font-weight: 500;

        @media(max-width:1024px) {
            font-size: 28px;
        }

        @media(max-width:640px) {
            font-size: 16px;
        }
    }

    &__iconWrap {
        @media(max-width:640px) {
            display: none;
        }
    }
}

.solutions__title {
    font-size: 24px;
    font-weight: 500;
    color: $text;
    margin-bottom: 32px;
    text-align: left;

    @media (max-width:1024px) {
        font-size: 20px;
        margin-bottom: 20px;
    }

    @media (max-width:640px) {
        font-size: 16px;
    }
}

.btnsWrap {
    &__btnWrap {
        width: 180px;

        @media(max-width:640px) {
            width: 288px;
        }
    }
}

.btnPrimary {
    background: none;
}