@import "../../../../scss/vars";

.template {
  &__addSettings {
    border-radius: 30px;
    box-shadow: 0px 8px 24px -4px rgba(24, 39, 75, 0.08), 0px 6px 12px -6px rgba(24, 39, 75, 0.12);
    padding: 35px 20px 60px 35px;
    margin-bottom: 30px;

    @media (max-width:640px) {
      padding: 16px;
    }
  }
}

.addSettings {
  &__title {
    color: $title;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;

    @media (max-width:525px) {
      font-size: 14px;
    }
  }
}

.filterParamWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 27px;
  padding-left: 8px;

  &:last-of-type {
    margin-bottom: 16px;
  }

  @media(max-width:1024px) {
    gap: 20px;
  }

  @media(max-width:640px) {
    gap: 15px;
  }
}

.filterParam {
  position: relative;
  display: flex;
  gap: 16px;
  align-items: center;
  border-radius: 20px;
  padding: 15px 21px;
  border: 0.6px solid $light_border;
  width: 290px;
  transition: all 0.5s;

  & svg {
    opacity: 0;
  }

  &:hover {
    border: 0.6px solid $bright_border;

    & svg {
      opacity: 1;
    }
  }

  @media(max-width:370px) {
    width: 100%;
  }
}

.filterClose {
  transition: all 0.5s;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 10px;
  width: 15px;
  height: 15px;
}

.filterParamImgWrap {
  /*     display: flex;
    justify-content: center;
    align-items: center; */
  padding: 10px;
  overflow: hidden;
  width: 60px;
  height: 60px;
  min-width: 60px;
  border-radius: 15px;
  border: 0.6px solid $light_border;

  & img {
    width: 100%;
    height: 100%;
  }

  @media(max-width:345px) {
    width: 53px;
    height: 53px;
    min-width: 53px;
  }
}