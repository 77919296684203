@import '../../../scss/vars';

.container {
  display: flex;
  gap: 60px;
}

.containerImage {
  width: 590px;
  height: 280px;
  overflow: hidden;
}

.image {
  object-fit: cover;
  margin: 0 auto;
}

.containerInfo {
  width: 590px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.title {
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  color: $title;
}

.description {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: $text;
  margin-bottom: 20px;
}

.button {
  width: 196px;
}

@media (max-width: 1170px) {
  .container {
    gap: 40px;
  }
  .containerInfo {
    width: 590px;
    gap: 15px;
  }
  .title {
    font-size: 26px;
    line-height: 34px;
  }
  .description {
    margin-bottom: 0;
  }
}

@media (max-width: 840px) {
  .container {
    flex-direction: column;
    align-items: center;
  }
  .containerInfo {
    width: 100%;
    gap: 20px;
  }
  .containerImage {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 19px;
    line-height: 24px;
  }
  .description {
    font-size: 14px;
  }
  .containerInfo {
    gap: 22px;
  }
  .button {
    width: 100%;
  }
}
